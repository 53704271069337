import React, { createContext, useContext, useState, useEffect } from "react";
import { btDemo, safariLight, safariDark } from "../constants/colors";
import { getUserInGameName, userData, getWalletBalance,getSubscription } from "../constants/axios";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Loader from "../Components/Loader";
import NotAuthorizedUser from "../Components/NotAuthorizedUser";
import CustomLoader from "../CommonComponent/CustomLoader";
import url from '../constants/url'
import { useNavigate } from 'react-router-dom';
import clevertap from "clevertap-web-sdk";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const defaultTheme = "btDemo";
  const {t} = useTranslation()
  const [theme, setTheme] = useState(defaultTheme);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  // console.log(queryParams)
  const [gameCode, setGameCode] = useState('')
  const [clientId, setClientId] = useState('')
  const [authCode, setAuthCode] = useState('')
  const [inNameGame, setInNameGame] = useState('')
  const [companyCode, setCompanyCode] = useState(null)
  const [accessKeyId, setAccessKeyId] = useState('')
  const [accessKeySecrete, setAccessKeySecrete] = useState('')
  const [loader, setLoader] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userId, setUserId] = useState(null)
  const [onMonUserId, setOnMonUserId] = useState(null)
  const [onmopayU, setOnmopayU] = useState(null)
  const [balance, setBalance] = useState(null)
 const [language, setLanguage] = useState('')
  const [isSubscription, setIsSubscription] = useState(null)
  const [subscriptionData, setSubscriptionData] = useState(null)
  clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
  clevertap.init(url.cleverTabDetails.cleverTapAccount, "global"); //
  

  const changeTheme = (option) => {
    if (option === "btDemo") {
      applyStyles("btDemo");
    } 
    else if (option === "safariLight") {
      applyStyles("safariLight");
    }
    else if (option === "safariDark") {
      applyStyles("safariDark");
    }


    // localStorage.setItem("selectedTheme", option);
    setTheme(option);
  };
  const resetTheme = () => {
    const root = document.documentElement;
    root.removeAttribute("style");

    localStorage.removeItem("selectedTheme");
    setTheme(defaultTheme);
  };
  const applyStyles = (option) => {
    const root = document.documentElement;


    const selectedOption = option === "btDemo" ? btDemo : (option === "safariLight" ? safariLight : (option === "safariDark" ? safariDark : defaultTheme));



    Object.entries(selectedOption).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value);
    });
  };

  const changeInNameGame = (name) => {
    setInNameGame(name)
  }
  const changeIsLoggedIn = (val) => {
    setIsLoggedIn(val)
  }

  const fetchUserData = async () => {
    setLoader(true)

    const body = {
      code: authCode,//"6f895f38-4541-3391-8a52-cbe5d3c050ab",
      clientId: clientId
    }
    let id = ''
    try {
      const res = await userData(body)
      setIsLoggedIn(true)
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('compnay', JSON.stringify(res?.data?.company))
      localStorage.setItem('user-id', res?.data?.param?._id)
      setCompanyCode(res?.data?.company)
      id = res?.data?.param?.user_id
      setUserId(res?.data?.param?.user_id)
      setOnMonUserId(res?.data?.param?.onMonUserId)
      getBalance(res?.data?.param?.onMonUserId)
      setOnmopayU(res?.data?.param?.onmopayU)
      oncheckSubscription(clientId,res?.data?.param?.onmopayU)
      clevertap.onUserLogin.push({
        Site: {
          Identity: res?.data?.param?.user_id,
          ClientId: clientId
        },
      });
    }
    catch (error) {
      console.log(error?.response?.data)
      setLoader(false)
      let data = JSON.stringify({ "action": "back" });
      window.parent.postMessage(data, "*");
    }
    const body1 = {
      search:
      {
        user: id,
        game: gameCode
      }

    }
    try {
      const res = await getUserInGameName(body1)
      // console.log("innname", res)
      if (res?.data.success) {
        setInNameGame(res?.data?.data?.[0]?.userINGameName)
        localStorage.setItem('inNameGame', res?.data?.data?.[0]?.userINGameName)
      }
    } catch (error) {
      console.log(error)
      // let data = JSON.stringify({ "action": "back" });
      // window.parent.postMessage(data, "*");
      // if (error?.response?.data)
      //   console.log("InnameGame error >>>>>", error?.response?.data)
    }
    setLoader(false)
  }
 const getBalance = async(onMonUserId) => {
    try {
      // console.log(onMonUserId, clientId);
      const res = await getWalletBalance(onMonUserId, clientId)
      // console.log(res)
      if (res.status === 200) {
        setBalance(res?.data)
      }
      // setLoader(false)
    }
    catch (error) {
      console.log(error)
      let data = JSON.stringify({ "action": "back" });
      window.parent.postMessage(data, "*");
     

    }
  }

  const oncheckSubscription = async (clientId,onmopayU) => {
    let body
    // console.log(clientId,'@@@@oncheckSubscription@@@@@',onmopayU);
    if(clientId && clientId==="sfesp" && onmopayU){
      //console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
      body = {
        svc : url.sfespdemoSubs.svcId,
        a:  url.sfespdemoSubs.appId,
        p:  url.sfespdemoSubs.packId,
        u: onmopayU,
        clientId:clientId
      }
    }
    else if(clientId && clientId==="BTdemo" && onmopayU){
      // console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
      body = {
        svc : url.BTdemoSubs.svcId,
        a:  url.BTdemoSubs.appId,
        p:  url.BTdemoSubs.packId,
        u:onmopayU,
        clientId:clientId
      }
    } else if(clientId && clientId==="demo"  && onmopayU){
      console.log("url.sfespdemoSubs.",url.sfespdemoSubs)
      body = {
        svc : url.demoSubs.svcId,
        a:  url.demoSubs.appId,
        p:  url.demoSubs.packId,
        u: onmopayU,
        clientId:clientId
      }
    }
    // console.log("^^^^subs body",body)
    const res = await getSubscription(body);
    // console.log("^^^^response",res?.data);
    // console.log("^^^^response",res?.data?.param.status);
    if(res.data.success && res?.data?.param && res?.data?.param?.status && res?.data?.param.status==='ACTIVE'){
      console.log("Subscription is Active")
      setIsSubscription(true);
      setSubscriptionData(res.data.param)
      // setTimeout(()=>{
      //   console.log("timeout")
      //   setIsSubscription(false);
      //   setSubscriptionData(null)
      // }, 1000);
     
      return true
    }else {
      console.log("Subscription is Not Active|Staus:",res?.data?.param,typeof res?.data?.param === 'string')
      if(typeof res?.data?.param === 'string' ){
        navigate("/subscribePage",{state:res?.data?.param})
      }else{
        setIsSubscription(false);
        setSubscriptionData(res.data.param)
      }
      // setIsSubscription(true);
      // setSubscriptionData({status:'ACTIVE'})
     
      return false
    }
  }


  useEffect(() => {
    const savedTheme = localStorage.getItem("selectedTheme");
    if (savedTheme) {
      changeTheme(savedTheme);
    }

    const isLoggedIn = localStorage.getItem('isLoggedIn')
    changeIsLoggedIn(isLoggedIn)
    // console.log(">>>",gameCode,clientId, authCode)
    document.title = t("title");
  }, []);


  useEffect(() => {
    let clientId_temp = searchParams.get("clientId");
    let gameCode_temp = searchParams.get("gameCode");
    let authCode_temp = searchParams.get("authCode");
    let lang_temp = searchParams.get("language");
    localStorage.setItem('onMobLanguage', lang_temp)
    i18next.changeLanguage(lang_temp);
    if (clientId_temp)
      setClientId(clientId_temp)
    if (gameCode_temp)
      setGameCode(gameCode_temp)
    if (authCode_temp)
      setAuthCode(authCode_temp)
    let themeValue = ''
    if (clientId_temp == 'BTdemo') {
      themeValue = 'btDemo'
      changeTheme(themeValue)
      localStorage.setItem('selectedTheme', themeValue)
    } 
    else if (clientId_temp == 'demo') {
      themeValue = 'safariLight'
      changeTheme(themeValue)
      localStorage.setItem('selectedTheme', themeValue)
    }
    else if (clientId_temp == 'sfesp') {
      themeValue = 'safariDark'
      changeTheme(themeValue)
      localStorage.setItem('selectedTheme', themeValue)
    }
    else {
      themeValue = 'btDemo'
    
      changeTheme(themeValue)
      localStorage.setItem('selectedTheme', themeValue)
    }

    const isLoggedIn = localStorage.getItem('isLoggedIn')
    // if(queryParams.size >0){
    //   queryParams.forEach((key,element) => {
    //     // console.log(element,key)
    //     if(element == 'clientId' )
    //      {
    //       setClientId(key)
    //       // clientId = key
    //       if(key =='demo'){
    //         changeTheme('btDemo')
    //         localStorage.setItem('selectedTheme','btDemo')
    //       }else{
    //         changeTheme('safariLight')
    //         localStorage.setItem('selectedTheme','safariLight')
    //       }
    //      }
    //      else if(element == 'gameCode' ){
    //       setGameCode(key)
    //       // console.log(key)
    //       // gameCode=key
    //      }
    //      else if(element == 'authCode' ){
    //       // setGameCode(key)
    //       // console.log(key)
    //       setAuthCode(key)
    //       // authCode=key
    //      }
    // });
    // }else{
    if (isLoggedIn) {
      localStorage.setItem('selectedTheme', themeValue)
      setInNameGame(localStorage.getItem('inNameGame'))
    }
    else localStorage.setItem('selectedTheme', 'safariLight')
    // }
    if (gameCode && clientId && authCode)
      fetchUserData()
  }, [gameCode, clientId, authCode])

  if (loader) return <Loader />
  if (!isLoggedIn) return <NotAuthorizedUser />
  return (
    <ThemeContext.Provider value={{ theme, clientId, authCode, gameCode, isLoggedIn, inNameGame, userId, changeInNameGame, onMonUserId, balance, onmopayU,subscriptionData,isSubscription,companyCode}}>
      {children}
    </ThemeContext.Provider>
  );
};
