import React, { useEffect, useState } from 'react'
import Previous from "../assets/images/previous.svg";
import { getContestByGameAndCompanyId, getMyContestList } from '../constants/axios';
import { formatAMPM, getProfiledateFormat } from './Common';
import CopyIcon from "../assets/images/copy-icon.svg";
import url from '../constants/url';
import Base from './Base';
import ContestStructure from './ContestStructure';
import { useTheme } from '../ThemeContext/ThemeContext';
import { Link, useNavigate } from 'react-router-dom';
import ContestDetailJoined from './ContestDetailJoined';
import Loader from './Loader';
import NotAuthorizedUser from './NotAuthorizedUser';
import { toast } from 'react-toastify';
import clevertap from "clevertap-web-sdk";
import { useTranslation } from "react-i18next";

const MyContest = () => {
  const { t } = useTranslation();
  const [contestDetails, setContestDetails] = useState([])
  const [selectedTab, setSelectedTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const [limitData, setlimitData] = useState(10);
  const [totalPage, setTotalPage] = useState(1)
  const [page, setPage] = useState(1)
  const globalValues = useTheme()
  const navigate = useNavigate()
  useEffect(() => {
    clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(url.cleverTabDetails.cleverTapAccount, "in"); //
    clevertap.setLogLevel(3);
    fetchContestDetails(page)
  }, []
  )
 
  const fetchContestDetails = async (page) => {
    setLoader(true);

    let body = {
      search: {
        game: globalValues?.gameCode,
        user: globalValues?.userId
      },
      pagination: {
        pageNo: page,
        recordPerPage: 10,
        sortBy: "dateTime",
        sortDirection: "desc"
      }
    }
    try {

      const res = await getMyContestList(body)
      console.log(res?.data?.data)
      if (res?.data?.success) {

        let  json_object = {"action": "event" ,"eventName":"Gamerji_GameListVisited", "event_properties":{
          "GameName":res?.data?.data[0]?.contestData?.game ,
          "ClientId":globalValues?.clientId,
          "Date": new Date() 
        }}
        let data = JSON.stringify(json_object);
        window.parent.postMessage(data, "*");
        setTotalPage(res?.data?.totalPages)
        setContestDetails([...contestDetails, ...res?.data?.data])
      }
      setLoader(false);

    } catch (error) {
      setLoader(false);

      console.log(error)
      if (error?.response.data?.data[0]?.msg)
        toast.error(error.response.data.data[0].msg)
      setLoader(false);
      let data = JSON.stringify({ "action": "back" });
      window.parent.postMessage(data, "*");
    }
  }
  const openContestDetails = () => {
    window.location.href = `/contest-details/:id`;
  }
  const loadHandler = async () => {
    let p = page + 1
    setPage(p)
    fetchContestDetails(p)
  };

  const contestHandler = (id) => {
    navigate(`/contest-details/${id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`, { state: { module: 'my_contest' } })
  }
  if (!globalValues?.isLoggedIn) return <NotAuthorizedUser />
  if (loader) return <Loader />
  return (
    <>
      <Base title={t("contest.my_contest")}>
     
        {
          contestDetails.length < 1 ? <div className="no_tournament">
            <h3>{t("contest.no_contest_found")}</h3>
          </div> :
            contestDetails?.map((item, index) => (
              <div className='contest-details' style={{ marginBottom: '20px', marginTop: "25px" }}
                onClick={(event) => {
                  // console.log("event",event);
                  if (event.target !== event.currentTarget) return;
                  contestHandler(item?.contestData?._id)
                }}
              >
                {/* <Link to={`/contest-details/${item?.contestData?._id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`} > */}
                <div className='status' style={{ background: item?.contestData?.status === "cancelled" ? "red" : item?.contestData?.status === "completed" ? "grey" : "green" }}>{item?.contestData?.status.toLowerCase()} </div>
                <ContestDetailJoined allData={item?.contestData} />
                {/* </Link> */}
              </div>

            ))
        }
        {
          page < totalPage ?
            <div className="padding-top2">
              <button
                style={{ cursor: 'pointer' }}
                onClick={loadHandler}>


                {t("contest.load_more")}

              </button>
            </div>
            : ""
        }

      </Base>

    </>
  )
}

export default MyContest