
import React, { useEffect, useState } from 'react'
import Base from './Base'
import Background from '../assets/images/background.jpg'
import PlayStore from '../assets/images/playstore.png'
import Google from '../assets/images/google.png'
import { useTheme } from '../ThemeContext/ThemeContext';
import { fetchHowToJoin ,gameTypebyClientId} from '../constants/axios'
import { useTranslation } from "react-i18next";

const HowToJoin = () => {
    const globalValues = useTheme()
    const [showLoader, setShowLoader] = useState(false)
    const [howtodata, setHowtodata] = useState(null)
    const [data2, setData] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        console.log("getHowToJoin")
        getHowToJoin()
        fetchGameType()
      }, [])
    
    
      const getHowToJoin = async () => {
        setShowLoader(true)
        let body={
            clientId:globalValues.clientId,
            game:globalValues.gameCode,
            q:"how to"
        }
        const res = await fetchHowToJoin(body)
        if (res?.success) {
         
            setHowtodata(res?.list)
        }
        setShowLoader(false)
      }
      useEffect(() => {
      
      console.log("howtodata",howtodata)
      }, [howtodata])
      const fetchGameType = async () => {
        setShowLoader(true)
        let body = {
          game: globalValues?.gameCode,//"634a42a89633f3f5b7228455",
          clientId: globalValues?.clientId,//"64bbc4e4f94a2cb38047d389"
        }
        try {
    
          const res = await gameTypebyClientId(body)
          // console.log(res?.data)
          if (res?.data?.success) {
            setData(res?.data?.data)
          }
          setShowLoader(false)
        }
        catch (error) {
          console.log(error)
          let data = JSON.stringify({ "action": "back" });
          window.parent.postMessage(data, "*");
    
        }
      }
    return (
        <Base title={t("how_To_join.game_instruction")}>

            {/* <div className='instructions'>
                <h4>Step 1</h4>
                <p style={{ fontSize: '14px' }}>Open ‘Battlegrounds Mobile India’ Game. If not already, download the game from app store</p>
                <div className='ins-img'>
                    <img src={Background} />
                </div>
                <div style={{display:'flex', justifyContent:"center", alignItems:'center', marginTop:'10px'}}>
                <div className='playstore' style={{marginRight:'20px'}}>
                    <img src={Google} />
                    </div>
                <div className='playstore'>

                    <img  src={PlayStore}/>
                    </div>
                </div>

            </div> */}
            {(howtodata && howtodata?.length>0 )? howtodata.map((data) => {
                console.log("data", data)
                return (<>
                    <div className='instructions'>
                        <h4>{data?.title}</h4>
                        <div className='ins-img ' style={{ marginTop: '10px', paddingBottom:"20px" }}>
                        {(data?.content && data?.content?.length > 0) && (
                            data.content.map((contentdata, index) => {
                                console.log("contentdata", index)
                                return (
                                    <>
                                        {contentdata.type === 'text' && <p style={{ fontSize: '14px',marginTop:'15px' }}>
                                            {contentdata.content}
                                        </p>}
                                        {(contentdata.type === 'image' &&(index===0||index===1)) &&  <>
                                           
                                                <img src={contentdata.content} style={{height:'181px'}}/>
                                          
                                            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop: '10px' }}>
                                                <div className='playstore' style={{ marginRight: '20px' }} onClick={()=>{window.open(data2.downloadLinkIos,"_blank")}}>
                                                    <img src={Google} />
                                                </div>
                                                <div className='playstore' onClick={()=>{window.open(data2.downloadLink,"_blank")} }>

                                                    <img src={PlayStore}  />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        {(contentdata.type === 'image' && (index!=0 && index!=1)) &&  <>

                                                <img src={contentdata.content} style={{height:'181px'}}/>
                                         
                                            </>
                                        }
                                    </>
                                )
                            })
                        )}
                          
                       </div> 
                    </div>

                </>)
            }) : (<>
                <h4 style={{textAlign:'center', color:'var(--headerText)', marginTop:'40%'}}>{t("how_To_join.no_Records_found")}</h4>
            </>)}
        </Base>
    )
}

export default HowToJoin
