import React, { useRef, useState } from 'react'
import Copy from '../assets/images/copy-icon.png'
import url from '../constants/url';
import Trophy from '../assets/images/Trophy.png'
import { formatAMPM, getProfiledateFormat } from './Common';
import ShowWinnersPopup from './ShowWinnersPopup';
import { toast } from 'react-toastify';
import { useTheme } from '../ThemeContext/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ContestDetailJoined = ({ allData }) => {
  const { t } = useTranslation();
  const [showWinners, setShowWinners] = useState(false);
  const pathname = (window.location.pathname).split('/')
  const globalValues = useTheme()
  const navigate = useNavigate()
  const copyableString = useRef();
  const getWinnerClick = () => {
    setShowWinners(true);

  };
  const copiedHandler = (e,value) => {
    e.preventDefault();
    navigator.clipboard.writeText(value);
    toast.success(t('contest.copied'));
  };

  const cancelWinner = () => {
    setShowWinners(false)
  }
  const contestHandler = (id) => {
    navigate(`/contest-details/${id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`)
  }
  return (
    <>
      {/* <div className='contest-details' style={{marginTop:'30px'}}> */}
      <div className='first'>
        <div className='box'>
          <h3>{t('contest.date')}</h3>
          <h2>{getProfiledateFormat(allData?.dateTime)}</h2>
        </div>
        <div className='box'>
          <h3>{t('contest.time')}</h3>
          <h2>{formatAMPM(new Date(allData?.dateTime))}</h2>
        </div>
        {/* <div className='box'>
          <h3>{" "}</h3>
          <h2>{" "}</h2>
        </div> */}

        <div className='box'>
          <h3>{t('contest.id')}</h3>
          <h2>{allData?.shortCode}</h2>
        </div>
      </div>
      <div className='first'>

        {allData?.titles?.slice(0, 2).map((itemInner, index) => {
          if (itemInner?.name !== "-" && itemInner?.isSelection) {
            return (
              <div className='box' key={index}>
                <h3> {itemInner?.name?.toLowerCase()}{" "}</h3>
                <h2>{itemInner?.value.toLowerCase()}</h2>
              </div >
            );
          }
        })}
        <div className='box'>
          <h3>{allData?.winningModel === "perRank" ? t('contest.winners') : t('contest.per_kill')}{" "}</h3>
          <h2>

            {
              allData?.winningModel === "perRank" ?
                <b
                  className="pointer"
                  onClick={() => getWinnerClick(allData)}

                >
                  {" "}

                  {/* <img src={Trophy} />  */}
                  <span >
                  <svg height="12px" width="12px" fill={`${globalValues.theme === "option2" ? "#000" : "#fff"}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21,4H18V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H3A1,1,0,0,0,2,5V8a4,4,0,0,0,4,4H7.54A6,6,0,0,0,11,13.91V16H10a3,3,0,0,0-3,3v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19a3,3,0,0,0-3-3H13V13.91A6,6,0,0,0,16.46,12H18a4,4,0,0,0,4-4V5A1,1,0,0,0,21,4ZM6,10A2,2,0,0,1,4,8V6H6V8a6,6,0,0,0,.35,2Zm8,8a1,1,0,0,1,1,1v1H9V19a1,1,0,0,1,1-1ZM16,8A4,4,0,0,1,8,8V4h8Zm4,0a2,2,0,0,1-2,2h-.35A6,6,0,0,0,18,8V6h2Z"></path></g></svg>
                  </span>
                  {" "}{allData?.totalWinners}
                </b>
                :
                <b className="" >
                  {((allData?.currency?.[0]?.outCurrency?.code ==
                    "INR" || allData?.currency?.[0]?.outCurrency?.code == 'inr') ? (
                    "₹"
                  ) : (<img
                    src={
                      url.imageUrl +
                      allData?.currency?.[0]?.outCurrency?.img?.default}
                    alt=""
                    className="coin_img"
                  />))}
                  {" "}
                  {allData?.killPoints}{" "}
                </b>
            }
          </h2>
        </div>
        <div className='box'>
          <h3>{t('contest.prize_pool')}</h3>
          <h2>
            {((allData?.currency?.[0]?.outCurrency?.code === "INR" || allData?.currency?.[0]?.outCurrency?.code == 'inr') ? (
              "₹"
            ) : (<img
              src={
                url.imageUrl +
                allData?.currency?.[0]?.outCurrency?.img?.default
              }
              alt=""
              className="coin_img"
            />))}
            {" "}
            {allData?.prizePool}</h2>
        </div >
      </div >
      <div className='second' style={{ marginBottom: '0' }}>
        <div style={{ display: 'flex' }}> <h2 style={{ marginRight: '10px' }}>{allData?.totalJoinedPlayers}/{allData?.totalPlayers}</h2> <h3>{t('contest.enteries_submitted')}</h3></div>
        <div className='inner'>
          <span style={{ width: `${100 * allData?.totalJoinedPlayers / allData?.totalPlayers}%`, color: 'yellow' }}></span>
        </div>

      </div>
      {
        pathname[1] == 'my-contest' && <div className='second' style={{ marginTop: '10px', padding: '0' }} onClick={() => contestHandler(allData._id)}>

          <button

          >
            <p>{t('contest.view_contest')}</p>
          </button>


        </div>
      }

      <div className='password-details'>
        <div className='firstlayer'>
          <span>{t('contest.room_id')}</span>
          {
            allData?.roomId ? <span >{allData?.roomId} <img src={Copy} alt='' onClick={(e) => { copiedHandler(e,allData?.roomId) }} /></span>
              : "-"
          }

        </div >
        <div className='firstlayer'>

          <span>{t('contest.password')}</span>
          {
            allData?.roomPassword ? <span>{allData?.roomPassword} <img src={Copy} alt='' onClick={(e) => { copiedHandler(e,allData?.roomPassword) }} /></span>
              : "-"
          }

        </div>

      </div >
      {showWinners == true && (
        <ShowWinnersPopup allWinners={allData} cancelWinner={cancelWinner} />
      )}
      {/* </div> */}

    </>
  )
}

export default ContestDetailJoined